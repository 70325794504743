import React from "react";
import styles from "./LandingLeftyRighty.module.scss";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../util/generateFluid";
import Button from "../elements/Button";
import FadeUp from "../effects/FadeUp";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row";

const LandingLeftyRighty = ({items, altButtonStyle, afterExtended, theme}) => {

    const itemsElements = items && items.length ? items.map((item,i) => {

        const aspectRatioImage = item.image && item.image[0] ? item.image[0].width / item.image[0].height : 1;

        return (
            <div key={i} className={styles.listItem}>
                <div className={styles.listItem__details}>
                    <FadeUp
                        className={styles.listItem__details__content}
                    >
                        <div>
                            {item.listTitle ? <h3>{item.listTitle}</h3> : null}
                            {item.listContent ?
                                <div className={`${styles.listItem__details__content__description} ${item.listLink && item.listLink.url ? styles.listItem__details__content__descriptionSpacingBelow : ""}`}>
                                    <span dangerouslySetInnerHTML={{__html: item.listContent}} />
                                </div>
                            : null}
                            {item.listLink && item.listLink.url ?
                                <Button
                                    url={item.listLink.url}
                                    target={item.listLink.target}
                                    external={item.listLink.target === "_blank"} text={item.listLink.text}
                                    filledStyle={!altButtonStyle}
                                    fillStyleIcon={!altButtonStyle}
                                    altColors={altButtonStyle}
                                    backgroundFilled={altButtonStyle}
                                    className={styles.button}
                                />
                            : null}
                        </div>
                    </FadeUp>
                    <div className={styles.listItem__details__imageContainer}>
                        {item.image && item.image.length ?
                            <GatsbyImage
                                fluid={[generateFluid(item.image[0], "(min-width: 1680px) 700px, (min-width: 600px) 42vw, 100vw")]}
                                className={styles.listItem__details__image}
                                style={{"--aspect-ratio": aspectRatioImage}}
                            />
                        : null}
                    </div>
                </div>
            </div>
        );
    }) : null;

    return itemsElements ? (
            <Section className={`${styles.list} ${afterExtended ? styles.listAfterExtended : ""}`} fullLeftyRighty={true} theme={theme}>
                <Container>
                    <Row>
                        <div className={styles.list__content}>
                            {itemsElements}
                        </div>
                    </Row>
                </Container>
            </Section>
    ) : null;
};

export default LandingLeftyRighty;