import React from "react";
import SEO from "../components/seo";
import HeroLanding from "../components/HeroLanding";
import { graphql } from "gatsby"
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import LandingLeftyRighty from "../components/LandingLeftyRighty";
import LinksList from "../components/LinksList";
import Background from "../components/layout/Background";

const secondaryLandingPage = (props) => {
    const craftData = props.data.craft.entry;

    const {title, secondaryLandingHero, primaryLandingList, secondaryLandingLinks = [], callToAction} = craftData || {};

    const heroData = secondaryLandingHero ? secondaryLandingHero[0] : {};
    const {displayTitle, introductionTitle, introductionContent, backgroundImage} = heroData;

    const leftyRightyData = primaryLandingList || {};

    const {linksTitle, linksContent, links} = secondaryLandingLinks[0] || {};
    const linksListItems = links && links.length ? links.map(item => {
        return {
            icon: item.icon,
            link: item.linkLink
        }
    }) : null;

    const ctaData = callToAction && callToAction.length ? callToAction[0] : {};
    const {ctaTitle: ctaText, primaryLink: ctaLink1, secondaryLink: ctaLink2} = ctaData;

    return (
        <Background theme="light">
            <SEO title={title || ""} seomatic={craftData.seomatic} />
            <HeroLanding
                pageTitle={introductionTitle || null}
                title={displayTitle || null}
                description={introductionContent || null}
                bgImage={backgroundImage && backgroundImage.length ? backgroundImage[0] : null}
                extended={true}
                theme={backgroundImage && backgroundImage.length ? "dark" : "light"}
            />
            <LandingLeftyRighty
                items={leftyRightyData}
                altButtonStyle={true}
                afterExtended={true}
                theme="light"
            />
            <LinksList
                title={linksTitle}
                content={linksContent}
                listItems={linksListItems}
                theme="light"
            />
            <Cta text={ctaText} link1={ctaLink1} link2={ctaLink2} theme="brightscan-dark" />
            <Footer
                theme="dark"
            />
        </Background>
    );
}


export const pageQuery = graphql`
    query tertiaryLandingPage($id: CRAFT_QueryArgument!) {
        
        craft {
            entry(id:[$id]) {
                title
                seomatic(asArray:true) {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                }
                ...on CRAFT_pages_tertiaryLandingPage_Entry {
                    secondaryLandingHero {
                        ...on CRAFT_secondaryLandingHero_BlockType {
                            displayTitle
                            introductionTitle
                            introductionContent
                            backgroundImage {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "heroImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "heroImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                        }
                    }
                    primaryLandingList {
                        ...on CRAFT_primaryLandingList_BlockType {
                            listTitle
                            listContent
                            listLink {
                                url
                                type
                                target
                                text
                            }
                            image {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "productLeftyRightyDesktopImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "productLeftyRightyDesktopImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                        }
                    }
                    secondaryLandingLinks {
                        ...on CRAFT_secondaryLandingLinks_BlockType {
                            linksTitle
                            linksContent
                            links {
                                ...on CRAFT_links_link_BlockType {
                                    linkLink {
                                        url
                                        type
                                        target
                                        customText
                                        text
                                    }
                                    icon {
                                        url
                                        contents
                                    }
                                }
                            }
                        }
                    }
                    callToAction {
                        ...on CRAFT_callToAction_BlockType {
                            ctaTitle
                            primaryLink {
                                type
                                url
                                text
                                target
                            }
                            secondaryLink {
                                type
                                url
                                text
                                target
                            }
                        }
                    }
                }
            }
        }
    }

`;


export default secondaryLandingPage;